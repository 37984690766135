// src/contexts/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';

// Create the WebSocket context
const WebSocketContext = createContext(null);

// This provider will wrap your app and manage the WebSocket connection
export function WebSocketProvider({ children }) {
    // Core WebSocket states
    const [ws, setWs] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [lastMessage, setLastMessage] = useState(null);
    const [reconnectAttempt, setReconnectAttempt] = useState(0);

    // Function to establish WebSocket connection
    const connectWebSocket = useCallback(() => {
        // Determine the correct WebSocket URL based on the environment
        const wsProtocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
        const wsHost = window.location.hostname === 'localhost' ? 'localhost:3001' : window.location.hostname;
        const wsUrl = `${wsProtocol}//${wsHost}/ws/`;

        console.log('Establishing WebSocket connection to:', wsUrl);
        
        // Create new WebSocket connection
        const websocket = new WebSocket(wsUrl);

        // Connection opened handler
        websocket.onopen = () => {
            console.log('WebSocket Connected ✅');
            setIsConnected(true);
            setReconnectAttempt(0); // Reset reconnection attempts on successful connection
        };

        // Message received handler
        websocket.onmessage = (event) => {
            try {
                // Parse incoming message
                const data = JSON.parse(event.data);
                // console.log('Received message:', data);
                setLastMessage(data);
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        // Connection closed handler
        websocket.onclose = (event) => {
            console.log('WebSocket Disconnected ❌', event.code, event.reason);
            setIsConnected(false);
            
            // Implement exponential backoff for reconnection
            const backoffTime = Math.min(1000 * Math.pow(2, reconnectAttempt), 10000);
            console.log(`Attempting to reconnect in ${backoffTime/1000} seconds...`);
            
            setTimeout(() => {
                setReconnectAttempt(prev => prev + 1);
                console.log('Reconnecting...');
                connectWebSocket();
            }, backoffTime);
        };

        // Error handler
        websocket.onerror = (error) => {
            console.error('WebSocket Error:', error);
        };

        // Store WebSocket instance in state
        setWs(websocket);

        // Cleanup function to properly close connection when needed
        return () => {
            if (websocket.readyState === WebSocket.OPEN) {
                console.log('Cleaning up WebSocket connection');
                websocket.close();
            }
        };
    }, [reconnectAttempt]); // Include reconnectAttempt in dependencies

    // Establish initial connection when the provider mounts
    useEffect(() => {
        const cleanup = connectWebSocket();
        
        // Clean up when the provider unmounts
        return cleanup;
    }, [connectWebSocket]);

    // Function for sending messages through the WebSocket
    const sendMessage = useCallback((type, payload = {}) => {
        if (ws?.readyState === WebSocket.OPEN) {
            const message = JSON.stringify({ type, ...payload });
            ws.send(message);
            console.log('Sent message:', { type, ...payload });
        } else {
            console.warn('WebSocket is not connected. Message not sent:', { type, payload });
        }
    }, [ws]);

    // Create the value object that will be provided to consumers
    const value = {
        isConnected,
        lastMessage,
        sendMessage,
        reconnectAttempt
    };

    // Provide the WebSocket context to children
    return (
        <WebSocketContext.Provider value={value}>
            {children}
        </WebSocketContext.Provider>
    );
}

// Custom hook for using the WebSocket connection
export function useWebSocket() {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
}