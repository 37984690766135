// telegramUtils.js

export const extractUserFromTelegramInitData = (initData) => {
  try {
    const decodedData = decodeURIComponent(initData);
    const params = Object.fromEntries(new URLSearchParams(decodedData));
    return JSON.parse(params.user); // Directly return the parsed user object
  } catch (error) {
    console.error('Failed to extract user from initData:', error);
    return null; // Return null on failure
  }
};


/* {
  "id": 2222020220,
  "first_name": "John Doe",
  "last_name": "",
  "language_code": "en",
  "is_premium": true,
  "allows_write_to_pm": true,
  "photo_url": "https://t.me/i/userpic/320/ddddddddddddddddddd.svg"
} */