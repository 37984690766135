import React, { useState, useEffect } from 'react';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { Menu, Users, Play, Coins, Settings, Crown, Send, Clock, AlertCircle } from 'lucide-react';
import { extractUserFromTelegramInitData } from './utils/telegramUtils';
import axios from 'axios';
import { useWebSocket } from './contexts/WebSocketContext';

const BettingGame = () => {
  const [user, setUser] = useState(null);
  const [roundID, setRoundID] = useState(0);
  const [multiplier, setMultiplier] = useState(2.99);
  const [gameStatus, setGameStatus] = useState('waiting');
  const [countdown, setCountdown] = useState(null);
  const [timerRef, setTimerRef] = useState(null);
  const [betAmount, setBetAmount] = useState("1");
  const [autoStopAt, setAutoStopAt] = useState("2");
  const [crashHistory, setCrashHistory] = useState([
    { value: 2.00 },
    { value: 1.50 },
    { value: 3.20 },
    { value: 1.20 },
    { value: 4.50 },
    { value: 1.80 },
    { value: 2.40 }
  ]);

  // Get the WebSocket connection from your context
  const { isConnected, lastMessage, sendMessage } = useWebSocket();

  // Handle incoming WebSocket messages
  useEffect(() => {
    if (lastMessage) {
      try {
        // Each message type represents a different game event
        switch (lastMessage.type) {
          case 'gameStart':
            console.log('New game starting!');
            setGameStatus('waiting');
            setRoundID(lastMessage.gameId);
    
            const startTime = Date.now();
            const endTime = startTime + lastMessage.delayToStart;
    
            // Update countdown every 100ms
            const timer = setInterval(() => {
              const remaining = Math.max(0, (endTime - Date.now()) / 1000);
              if (remaining === 0) {
                setGameStatus('running');
                clearInterval(timer);
                setCountdown(null);
              } else {
                setCountdown(remaining);
              }
            }, 100);
    
            setTimerRef(timer);
            break;
            
          case 'multiplierUpdate':
            // Smoothly update the multiplier as it increases
            setGameStatus('running');
            setMultiplier(parseFloat(lastMessage.multiplier).toFixed(2));
            break;
            
          case 'crash':
            // console.log('Game crashed at:', lastMessage.finalMultiplier);
            setMultiplier(parseFloat(lastMessage.finalMultiplier).toFixed(2));
            setGameStatus('crashed');
            if (lastMessage.crashHistory) {
              setCrashHistory([...lastMessage.crashHistory]);
            }
            // You might want to add effects or animations here when the game crashes
            break;
            
          case 'gameState':
            // Update the game state when first connecting or reconnecting
            setGameStatus(lastMessage.status);
            setRoundID(lastMessage.gameId);
            setMultiplier(parseFloat(lastMessage.currentMultiplier).toFixed(2));
            if (lastMessage.crashHistory) {
              setCrashHistory([...lastMessage.crashHistory]);
            }
            break;
        }
      } catch (error) {
        console.error('Error handling game message:', error);
      }
    }
  }, [lastMessage]); // This effect runs whenever a new message arrives

  // Cleanup timer when component unmounts or when timer changes
  useEffect(() => {
    return () => {
      if (timerRef) {
        clearInterval(timerRef);
      }
    };
  }, [timerRef]);

  const fakeInitData = 
    `user=` + encodeURIComponent(`${JSON.stringify({
      id: 123456799,
      first_name: "John Doe",
      last_name: "Doe",
      language_code: "en",
      is_premium: false,
      allows_write_to_pm: true,
      photo_url: "https://example.com/fake-photo.png"
    })}`) + '&chat_instance=9876543210&chat_type=private&auth_date=1733657992&signature=fakesignature&hash=fakehash'
  ;

  useEffect(() => {
    const initDataRaw = window.Telegram.WebApp.initData ? window.Telegram.WebApp.initData : fakeInitData;
    
    if (initDataRaw) {
      axios.defaults.headers.common['Authorization'] = `tma ${initDataRaw}`;
      axios.post('/api/login', null);
    }

    // Use the utility function to extract the user
    const extractedUser = extractUserFromTelegramInitData(initDataRaw);
    setUser(extractedUser);
  }, []);

  // window.Telegram.WebApp.requestFullscreen();
  
  const players = [
    { user: 'user50KTY', bet: 720, profit: '-', isVIP: true, telegramId: user?.first_name },
    { user: 'onemore1', bet: 431, profit: '-', isVIP: true, telegramId: '@onemore1' },
    { user: 'Darkminion', bet: 128, profit: '-', telegramId: '@dark_minion' },
    { user: 'R823', bet: 51, profit: '-', telegramId: '@R823_t' },
    { user: 'Ihnatnic', bet: 15, profit: '-', telegramId: '@ihnatnic' },
    { user: 'OLLS', bet: 13, profit: '-', telegramId: '@OLLS_game' },
    { user: 'M00nd4nc3r', bet: 12, profit: '-', telegramId: '@M00nd4nc3r' },
    { user: '4TRE', bet: 10, profit: '-', telegramId: '@4TRE_bet' }
  ];



  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-black text-white">
      {/* Header */}
      <header className="flex justify-between items-center p-2 bg-gradient-to-r from-purple-900/50 to-indigo-900/50 backdrop-blur-sm">
      <div className="flex items-center space-x-2">
        <h1 className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
          Hypurr Bet
        </h1>
        <span className="text-[10px] px-1.5 py-0.5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full">ALPHA</span>
      </div>
      <div className="flex items-center gap-1.5">
        <AlertCircle className="w-4 h-4 text-purple-400 hover:text-purple-300 cursor-pointer" />
        <Menu className="w-4 h-4 text-gray-400 hover:text-gray-300 cursor-pointer" />
      </div>
    </header>

      {/* Game Graph */}
      <div className="m-4 p-4 rounded-2xl bg-gradient-to-b from-gray-800/50 to-gray-900/50 backdrop-blur-sm border border-gray-800">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-purple-400" />
            <span className="text-sm font-medium text-purple-400">Round #{roundID}</span>
          </div>
          <span className="text-sm text-pink-500">Max profit: ฿7.58</span>
        </div>
        <div className="h-48 relative">
        <div className="absolute inset-0 flex items-center justify-center flex-col">
        {gameStatus === 'crashed' ? (
          <>
            <span className="text-2xl text-gray-400">Crashed at</span>
            <span className="text-6xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
              x{multiplier}
            </span>
          </>
        ) : gameStatus === 'running' && (
          <span className="text-6xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 text-transparent bg-clip-text">
            x{multiplier}
          </span>
        )}
 
        {gameStatus === 'waiting' && countdown && (
          <span className="text-xl text-gray-400 mt-2">
            New game in {countdown.toFixed(3)}s
          </span>
        )}
</div>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={crashHistory.map((item, index) => ({ ...item, index }))}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="url(#gradient)"
                strokeWidth={3}
                dot={false}
              />
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="1" y2="0">
                  <stop offset="0%" stopColor="#8B5CF6" />
                  <stop offset="100%" stopColor="#EC4899" />
                </linearGradient>
              </defs>
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="flex justify-between mt-4 bg-gray-800/30 p-2 rounded-xl">
          {crashHistory?.map((crash, index) => {
            const value = Number(crash.value);
            return (
              <span
                key={index}
                className={`text-sm font-medium ${
                  value >= 2 ? 'text-green-400' : 'text-red-400'
                }`}
              >
                x{value.toFixed(2)}
              </span>
            );
          })}
        </div>
      </div>

      {/* Betting Controls */}
      <div className="m-4 p-4 rounded-2xl bg-gradient-to-r from-purple-900/20 to-pink-900/20 backdrop-blur-sm border border-purple-800/30">
 <div className="flex flex-col space-y-3">  {/* Reduced space-y from 4 to 3 */}
   {/* Bet Input Group */}
   <div className="bg-gray-800/50 rounded-lg p-1.5">  {/* Reduced padding from p-2 to p-1.5 */}
     <label className="text-sm text-gray-400 block">Bet</label>  {/* Removed mb-1 */}
     <div className="flex items-center">
       <input
         type="number"
         value={betAmount}
         onChange={(e) => setBetAmount(e.target.value)}
         className="w-full bg-transparent border-none outline-none text-white"  
         min="1"
         step="1"
       />
       <span className="text-gray-400 text-sm">bits</span>
     </div>
   </div>

   {/* Payout Input Group */}
   <div className="bg-gray-800/50 rounded-lg p-1.5">  {/* Reduced padding */}
     <label className="text-sm text-gray-400 block">Payout</label>
     <div className="flex items-center">
       <input
         type="number"
         value={autoStopAt}
         onChange={(e) => setAutoStopAt(e.target.value)}
         className="w-full bg-transparent border-none outline-none text-white"
         min="1.01"
         step="0.01"
       />
       <span className="text-gray-400">×</span>
     </div>
   </div>

   {/* Bottom Row: Stats and Button */}
   <div className="flex items-center justify-between">
     {/* Stats */}
     <div className="flex space-x-4 text-sm text-gray-400">
       <div>Target Profit: <span className="text-white">{(betAmount * autoStopAt).toFixed(2)} bits</span></div>
       <div>Win Chance: <span className="text-white">{(99 / autoStopAt).toFixed(2)}%</span></div>
     </div>
     
     {/* Button */}
     <button className="px-6 py-2 bg-gradient-to-r from-purple-500 to-pink-500 rounded-lg hover:opacity-90 transition-opacity">
       BET
     </button>
   </div>
 </div>
</div>

      {/* Players Table */}
      <div className="m-4 rounded-2xl overflow-hidden bg-gray-800/50 backdrop-blur-sm border border-gray-800">
        <div className="p-4 bg-gray-900/50">
          <div className="flex justify-between items-center">
            <span className="font-medium">Live Bets</span>
            <span className="text-sm text-gray-400">Active Players</span>
          </div>
        </div>
        <div className="divide-y divide-gray-700">
          {players.map((player, index) => (
            <div key={index} className="flex items-center justify-between p-4 hover:bg-gray-700/20 transition-colors">
              <div className="flex items-center gap-2">
                {player.isVIP && <Crown className="w-4 h-4 text-yellow-500" />}
                <span className="text-purple-400">{player.telegramId}</span>
              </div>
              <div className="flex items-center gap-8">
                <span className="text-gray-400">-</span>
                <span className="text-pink-400">{player.bet}</span>
                <span className="text-gray-400">{player.profit}</span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Footer Stats */}
      <div className="fixed bottom-0 left-0 right-0 bg-gray-900/90 backdrop-blur-sm border-t border-gray-800 p-4">
        <div className="max-w-7xl mx-auto flex justify-between items-center">
          <button className="p-2 hover:bg-white/10 rounded-lg transition-all">
            <Settings className="text-purple-400" />
          </button>
          <div className="flex gap-6 text-sm">
            <div className="flex items-center gap-2">
              <Users className="w-4 h-4 text-purple-400" />
              <span className="text-gray-300">227</span>
            </div>
            <div className="flex items-center gap-2">
              <Play className="w-4 h-4 text-pink-400" />
              <span className="text-gray-300">42</span>
            </div>
            <div className="flex items-center gap-2">
              <Coins className="w-4 h-4 text-yellow-400" />
              <span className="text-gray-300">15,074 bits</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BettingGame;